
import {
  defineComponent,
  PropType,
} from "vue";
import CreateReportFormModal
  from "@/modules/registration-reports/components/CreateReportFormModal/CreateReportFormModal.vue";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import LoadReportForm from "@/modules/registration-reports/components/LoadReportForm.vue";
import ReportFormContextMenu from "@/modules/editor-forms/components/ReportFormContextMenu/ReportFormContextMenu.vue";
import { FormsExtraInfo } from "@/services/AppApiFormsService/types/FormsExtraInfo";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import AppApiRepositoryService from "@/services/AppApiRepositoryService/AppApiRepositoryService";
import {
  JournalRepositoryDto,
  JournalValueDto,
} from "@/services/AppApiRepositoryService/types/JournalRepositoryDto";
import { JournalRequiredFieldsDto } from "@/services/AppApiRepositoryService/types/JournalRequiredFieldsDto";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import { ActionButtonDto } from "@/services/ApiActions/types";
import {
  JournalDataSet,
  JournalDataSetStaticParams,
} from "@/modules/registration-reports/classes/JournalDataSet";
import {
  ApiHelper,
  BlueHelpComponent,
  ButtonComponent,
  Dictionary,
  EnterModalComponent,
  EnterModalProps,
  FormInfoDataDto,
  FormReadDto,
  getStatusReportName,
  GlobalContext,
  isNullOrUndefined,
  Logger,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  RepoService,
  RepositoryReadTypeDto,
  ScreenSpinner,
  TABLE_CLASSES,
  TABLE_CONSTS,
  TABLE_ICONS,
  TableActionsComponent,
  TableClasses,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
  TablePagination,
  TablePaginationBottom,
  TableRow,
  VOC_NAMES_DICT,
} from "table";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { TableRowObj } from "table/dist/types/TableRowObj";
import { PaginationMode } from "table/dist/types/LazyPaginationState";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";
import ExportWirthTitlePagesModal, { ExportWirthTitlePagesProps } from "@/modules/editor-forms/components/ExportWirthTitlePagesModal/ExportWirthTitlePagesModal.vue";
import { PanelFiltersSave } from "table/dist/components/PanelFilters/types";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import { PageGlobalContext } from "table/dist/services/GlobalContext/types";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";
import { ActionButtonActionsFuncDict } from "@/components/ActionButton/ActionButton.vue";

interface JournalData {
  tableDataSet: TableDataSet;
  factory: TableDataSetFactory;
  open: {
    info: boolean;
  };
}

export default defineComponent({
  name: "ReportPanelListV3",
  components: {
    ActionsButtons,
    ExportWirthTitlePagesModal,
    CreateReportFormModal,
    ButtonComponent,
    LoadReportForm,
    ReportFormContextMenu,
    TableComponent,
    TableMultiHeaders,
    BlueHelpComponent,
    EnterModalComponent,
    TablePaginationBottom,
    ScreenSpinner,
    TableActionsComponent,
    PanelFilters,
  },
  props: {
    tableClasses: {
      type: Object as PropType<TableClasses>,
      default: TABLE_CLASSES,
    },
    preset: String,
    filter: String,
  },
  setup() {
    return {
      TABLE_ICONS,
      getStatusReportName,
      VOC_NAMES_DICT,
      TableRow,
    };
  },
  data() {
    return {
      open: {
        export: null as null | ExportWirthTitlePagesProps,
        createReportForm: false,
        loadReportForm: false,
        enterModal: null as null | EnterModalProps,
      },
      loading: {
        unload: false,
      },
      table: null as JournalData | null,
      formsExtraInfo: null as FormsExtraInfo | null,
      journalRepository: null as JournalRepositoryDto | null,
      formsJournals: null as null | ApiListWrapper<JournalValueDto>,
      actionsReportForms: null as ActionButtonDto[] | null,
      journalDataSet: null as JournalDataSet | null,
      presets: undefined as undefined | PanelFiltersSave[],
      isCreated: false,
      isUnmounted: false,
    };
  },
  async created() {
    await wrapNotifyAndAwaited([
      async () => {
        this.formsJournals = (
          await AppApiRepositoryService.getFormsJournals()
        ).json;
      },
      async () => {
        this.formsExtraInfo = (await AppApiFormsService.extraInfo()).json;
      },
      async () => {
        const pathActions = ["ReportForms", this.routeParams.journal_name]
          .filter(Boolean)
          .join(".");
        this.actionsReportForms = await ApiActionsService.getActions(
          pathActions,
        );
      },
    ]);

    await ApiHelper.wrapNotifyError(
      async () => {
        await this.updateJournal(true);
      },
      {
        reject: () => {
          return this.isUnmounted;
        },
      },
    );

    this.$watch(
      () => this.types,
      () => {
        if (!this.types) {
          return;
        }

        RepoService.setAttrTypes(this.types);
      },
      { immediate: true },
    );

    this.$watch(
      () => this.preset,
      async () => {
        await this.updateJournal(true);
      },
    );

    this.$watch(
      () => this.routeParams.journal_name,
      async () => {
        if (!this.routeParams.journal_name) {
          return;
        }

        await this.updateJournal(true);
      },
    );

    this.isCreated = true;
  },
  async beforeUnmount() {
    this.isUnmounted = true;
    this.destroyTable();
    await this.destroyJournalDataSet();
  },
  computed: {
    actionsFuncDict(): ActionButtonActionsFuncDict {
      const {
        onDownloadOfflinePart,
        onExport,
        onCreateAdjustmentReport,
        onOpenCreateReportForm,
      } = this;
      return {
        onDownloadOfflinePart,
        onExport,
        onCreateAdjustmentReport,
        onOpenCreateReportForm,
      };
    },

    types(): Dictionary<RepositoryReadTypeDto> | undefined {
      return this.journalRepository?.types ?? undefined;
    },

    presetsId(): string | undefined {
      if (!this.journalDataSet) {
        return undefined;
      }

      return (
        "report-panel-list-v3" +
        "_" +
        this.journalDataSet.params.staticParams.journal_name +
        "_" +
        this.journalDataSet.params.staticParams.preset
      );
    },
    formJournalCaption(): string {
      if (this.formJournal === null) {
        return "Загрузка..";
      }

      if (this.formJournal === undefined) {
        return `Журнал "${this.routeParams.journal_name}" не найден`;
      }

      let caption = this.formJournal.label;
      if (this.formJournalPreset) {
        caption += ` - ${this.formJournalPreset.label}`;
      }

      return caption;
    },
    // null - загрузка, undefined - не найдено
    formJournal(): JournalValueDto | undefined | null {
      if (isNullOrUndefined(this.formsJournals)) {
        return null;
      }

      return this.formsJournals.result.find(
        (x) => x.value === this.routeParams.journal_name,
      );
    },
    formJournalPreset(): ApiSelectValue | undefined {
      if (isNullOrUndefined(this.preset)) {
        return undefined;
      }

      return this.formJournal?.presets.find((x) => x.value === this.preset);
    },

    routeParams(): { journal_name?: string } {
      return this.$route.params as any;
    },

    isDisabledUnload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.unload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },

    isDisabledUpload(): boolean {
      return (
        !this.formsExtraInfo ||
        !this.formsExtraInfo.right_to_act.upload_exchange_file ||
        Object.keys(this.selectedForms).length === 0 ||
        this.loading.unload
      );
    },

    isDisabledExport(): boolean {
      return Object.keys(this.selectedForms).length === 0;
    },

    selectedValues(): TableRowObj[] {
      if (!this.table) {
        return [];
      }
      return this.table.tableDataSet.rows.filter(
        (x) => x.select,
      ) as TableRowObj[];
    },

    selectedForms(): Dictionary<JournalRequiredFieldsDto> {
      return this.selectedValues.reduce((selectedForms, tableRow) => {
        const form: JournalRequiredFieldsDto = tableRow.original;
        selectedForms[form.report_form_id] = form;
        return selectedForms;
      }, {} as Dictionary<JournalRequiredFieldsDto>);
    },
  },
  methods: {
    async initJournalDataSet(journalRepository: JournalRepositoryDto) {
      if (!this.routeParams.journal_name) {
        return;
      }

      await this.destroyJournalDataSet();
      const staticParams: JournalDataSetStaticParams = {
        action: "list_for_journal",
        journal_name: this.routeParams.journal_name,
        preset: this.preset,
      };
      const serverFilterSort =
        journalRepository.table.options?.server_filter_sort !== false;
      this.journalDataSet = new JournalDataSet();
      await this.journalDataSet.setParams({
        staticParams,
        uniqueId: `${this.routeParams.journal_name}_${this.preset}`,
        lazyLoadSize: serverFilterSort
          ? journalRepository.lazy_load_size
          : undefined,
        filter: serverFilterSort ? this.filter : undefined,
        listUrl: `/api/forms?`,
        getRowAttrs: (x) => x,
      });
    },

    destroyTable() {
      if (this.table) {
        this.table.tableDataSet.destroy();
      }

      this.table = null;
    },

    async destroyJournalDataSet() {
      if (this.journalDataSet) {
        await this.journalDataSet.destroy();
      }

      this.journalDataSet = null;
    },

    async initTable() {
      if (this.isUnmounted) {
        return;
      }

      if (
        !this.journalRepository ||
        !this.journalDataSet ||
        !this.routeParams.journal_name
      ) {
        throw new Error("not found data");
      }

      this.destroyTable();
      const filtersSaveModule = new PanelFiltersSaveModule(this.presetsId!);
      await filtersSaveModule.init();
      const filtersSet = new PanelFiltersSet(filtersSaveModule);
      filtersSet.subject.subscribe((data) =>
        applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
      );

      const dataSetData = await this.journalDataSet!.get();
      const journalValues: JournalRequiredFieldsDto[] =
        dataSetData?.values ?? [];
      const factory = new TableDataSetFactory({
        tableName: "journal",
        uniqId: this.routeParams.journal_name,
        headers: this.journalRepository.table.headers,
        types: this.journalRepository.types,
        rows: journalValues.map((journal) => {
          return journal as Dictionary;
        }),
        edit: {},
        modelDtoArray: this.journalRepository.table.model,
        tableDataSetOptions: {
          pagination: this.journalDataSet.pagination as TablePagination,
          display: {
            incCol: 1,
            incRow: 0,
            rowIndexCol: false,
          },
          listeners: {
            destroy: {
              next: (tableDataSet) => {
                this.journalDataSet?.tableDataSetDestroy(tableDataSet);
              },
            },
            changeFilters: {
              next: async () => {
                if (!this.journalDataSet || !this.table) {
                  return;
                }

                await this.journalDataSet.onChangeFilters(
                  this.table.tableDataSet as TableDataSet,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
            changeSort: {
              next: async () => {
                if (!this.journalDataSet || !this.table) {
                  return;
                }

                await this.journalDataSet.onChangeSort(
                  this.table.tableDataSet as TableDataSet,
                  this.table.factory as TableDataSetFactory,
                );
              },
            },
          },
          filtersSet,
          uniqueWordWrapSave: true,
          getUUID: (original, tableRow) => {
            return (
              original?.report_form_id ??
              tableRow?.uuid ??
              TABLE_CONSTS.DEFAULT_MAKE_UUID()
            );
          },
        },
        enableAutoSaveSettings: true,
      });
      const tableDataSet = await factory.create();
      this.journalDataSet.setInfoData(tableDataSet, journalValues);
      this.table = {
        tableDataSet,
        factory,
        open: {
          info: true,
        },
      };
      this.journalDataSet!.updateValuesRemain(
        this.table!.tableDataSet as TableDataSet,
      );
    },

    async updateJournal(updateRepository: boolean) {
      if (this.isUnmounted || !this.routeParams.journal_name) {
        return;
      }

      await ApiHelper.wrapNotifyError(
        async () => {
          if (updateRepository || !this.journalRepository) {
            this.journalRepository = null;
            const { json: journalRepository } =
              await AppApiRepositoryService.getJournalRepository(
                this.routeParams.journal_name!,
              );

            this.journalRepository = journalRepository;
          }

          await this.initJournalDataSet(this.journalRepository!);
          await this.initTable();
        },
        {
          reject: () => {
            return this.isUnmounted;
          },
        },
      );
    },

    updateFormRead(formRead: FormReadDto) {
      if (!this.table) {
        return;
      }

      const tableRow = this.table.tableDataSet.rows.find(
        (x: TableRowObj) =>
          (x.original as JournalRequiredFieldsDto).report_form_id ===
          formRead.report_form_id,
      );
      if (!tableRow) {
        return;
      }

      const formReadCurrent = tableRow.original as JournalRequiredFieldsDto;
      Object.assign(formReadCurrent, formRead);
      const pointer = {
        row: tableRow.row,
        col_name: "form_name",
      };
      const formInfoData: FormInfoDataDto | undefined = formRead.form_comment
        ?.text
        ? {
          pointer,
          value: formRead.form_comment as FormInfoDataDto["value"],
        }
        : undefined;

      this.table.tableDataSet.info.setDataByPointer(
        "comment",
        pointer,
        formInfoData,
      );
    },

    notifySelectReportingForms() {
      NotificationsService.send({
        type: "error",
        title: "Ошибка",
        text: "Не выбраны отчетные формы",
        duration: 5000,
      });
    },

    async onDeleteReport(report: ReportDto) {
      try {
        const result = (
          await ApiServiceRegReports.deleteReport(report.report_id)
        ).json;
        if (!result) {
          await this.updateJournal(false);
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при удалении отчёта",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
      }
    },

    async onSuccessDeleteForm({ form }: { form: JournalRequiredFieldsDto }) {
      await this.updateJournal(false);
    },

    async onAddReport(report: ReportDto) {
      await this.updateJournal(false);
    },

    onExport() {
      if (this.isDisabledExport) {
        this.notifySelectReportingForms();
        return;
      }

      this.open.export = { form_id: Object.keys(this.selectedForms) };
      this.onSelectedAll("clear");
    },

    onUnloadCpFile() {
      ApiServiceRegReports.formUnloadCpFile(Object.keys(this.selectedForms));
    },

    async onCreateAdjustmentReport() {
      if (Object.keys(this.selectedForms).length === 0) {
        this.notifySelectReportingForms();
        return;
      }

      if (this.loading.unload) {
        NotificationsService.send({
          type: "error",
          title: "Ошибка",
          text: "Дождитесь окончания предыдущего действия",
          duration: 5000,
        });
        return;
      }

      this.loading.unload = true;
      try {
        await ApiServiceRegReports.formsCreateAdjustment(
          Object.keys(this.selectedForms).map(Number),
        );
        await this.updateJournal(false);
      } catch (e) {
        await ApiHelper.wrapNotifyError(e, {
          isError: true,
          title:
            "Произошла ошибка при создании корректировки для отчётной формы",
        });
      }
      this.loading.unload = false;
    },

    async onActionExec(action: ActionButtonDto) {
      if (!this.table?.tableDataSet || !action.url) {
        return;
      }

      try {
        const tableDataSet = this.table.tableDataSet;
        tableDataSet.updateGlobalContext();
        const { selected_cell } = GlobalContext.get() as PageGlobalContext;
        const selected_row = tableDataSet.getSelectedRow()?.original;
        const selected_rows = this.selectedValues.map((x) => x.original);
        const result = await ApiActionsService.execute(action, {
          selected_rows,
          selected_row,
          selected_cell,
        });
        const refresh = result.result?.refresh;
        if (refresh) {
          await this.updateJournal(refresh === "all");
        }
      } catch (ex) {
        Logger.error(ex);
        await ApiHelper.wrapNotifyError(ex, { isError: true });
      }
    },

    setHelp(table: JournalData, pointer: Required<TableCellPointer> | null) {
      if (!pointer) {
        return;
      }

      const model = table.tableDataSet.getModelOrUndefined(pointer.col_name);
      table.tableDataSet.helpHelper.setHelp(model?.modelDto.help || null, {
        tableDataSet: table.tableDataSet,
        field: pointer.col_name,
      });
    },

    onSwitchRow(row: number) {
      const tableRow = this.table!.tableDataSet.getRowOrUndefined(row);
      if (!tableRow) {
        return;
      }

      tableRow.select = !tableRow.select;
    },

    onSelectedAll(mode: "" | "clear" | "all" = "") {
      if (!this.table) {
        return;
      }

      const isSelectedAll = this.table.tableDataSet.isSelectedAll;
      if (mode === "clear" || isSelectedAll) {
        this.table.tableDataSet.rows.forEach((x) => (x.select = false));
      } else {
        this.table.tableDataSet.visibleRows.forEach((tableRow) => {
          tableRow.select = true;
        });
      }
    },

    openContextMenuWithReportForm(event: MouseEvent, form: FormReadDto) {
      this.getReportFormContextMenuComponent().open(event, form);
    },

    getReportFormContextMenuComponent() {
      return this.$refs.reportFormContextMenu as InstanceType<
        typeof ReportFormContextMenu
      >;
    },

    async onPagination(mode: PaginationMode) {
      if (!this.journalDataSet || !this.table) {
        return;
      }

      await this.journalDataSet.paginationLoad(
        mode,
        this.table.tableDataSet as TableDataSet,
        this.table.factory as TableDataSetFactory,
      );
    },

    onDownloadOfflinePart() {
      this.open.loadReportForm = true;
    },

    onOpenCreateReportForm() {
      const create_report_form =
        this.journalRepository?.edit?.create_report_form ?? true;
      if (!create_report_form) {
        NotificationsService.send({
          type: "error",
          title: "Ошибка",
          text: "Создание отчётной формы запрещено",
          duration: 5000,
        });
        return;
      }

      this.open.createReportForm = true;
    },
  },
});
