
import {
  defineComponent,
  PropType,
} from "vue";
import {
  ApiHelper,
  ModalComponent,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
} from "table";
import { ExportWirthTitlePages } from "@/services/AppApiService/types";
import AppApiService from "@/services/AppApiService/AppApiService";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";

export interface ExportWirthTitlePagesProps {
  form_id: Array<string | number>;
}

export default defineComponent({
  name: "ExportWirthTitlePagesModal",
  components: {
    PanelFilters,
    TableActionsComponent,
    ModalComponent,
    TableComponent,
    TableMultiHeaders,
    ScreenSpinner,
  },
  props: {
    propsObject: {
      type: Object as PropType<ExportWirthTitlePagesProps>,
      required: true,
    },
  },
  emits: ["close"],
  setup() {
    return {
      TABLE_ICONS,
    };
  },
  data() {
    return {
      export: null as ExportWirthTitlePages | null,
      table: null as null | {
        tableDataSet: TableDataSet;
        factory: TableDataSetFactory;
      },
      loading: true,
    };
  },
  computed: {},
  watch: {},
  async created() {
    await ApiHelper.wrapNotifyError(
      async () => {
        const formId = this.propsObject.form_id;
        const exportResult = (await AppApiService.exportWithTitlePages(formId))
          .json;
        this.export = exportResult;
        const uniqId = "exportWithTitlePages";
        const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
        await filtersSaveModule.init();
        const filtersSet = new PanelFiltersSet(filtersSaveModule);
        filtersSet.subject.subscribe((data) =>
          applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
        );
        const factory = new TableDataSetFactory({
          rows: exportResult.data,
          edit: {},
          types: exportResult.repository.types || {},
          headers: exportResult.repository.headers,
          tableName: `export`,
          modelDtoArray: exportResult.repository.model,
          uniqId,
          tableDataSetOptions: {
            filtersSet,
          },
        });
        const tableDataSet = await factory.create();
        this.table = {
          factory,
          tableDataSet,
        };
        this.loading = false;
      },
      { title: "Загрузка данных для экспорта" },
    );
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
