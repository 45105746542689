import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "reg-reports-v3 default-page-container" }
const _hoisted_2 = {
  ref: "regReportsWithoutTableContainer",
  class: "reg-reports-without-table-container default-page-padding-inline"
}
const _hoisted_3 = {
  ref: "toolbarRef",
  class: "reg-reports-v3-toolbar"
}
const _hoisted_4 = {
  key: 0,
  style: {"flex-grow":"1"}
}
const _hoisted_5 = { class: "reg-reports-v3-list-container" }
const _hoisted_6 = { class: "table-scroll-container" }
const _hoisted_7 = {
  key: 0,
  class: "flex-full-center"
}
const _hoisted_8 = { style: {"position":"fixed","right":"0px","bottom":"0px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ActionsButtons = _resolveComponent("ActionsButtons")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_TablePaginationBottom = _resolveComponent("TablePaginationBottom")!
  const _component_BlueHelpComponent = _resolveComponent("BlueHelpComponent")!
  const _component_CreateReportFormModal = _resolveComponent("CreateReportFormModal")!
  const _component_LoadReportForm = _resolveComponent("LoadReportForm")!
  const _component_ReportFormContextMenu = _resolveComponent("ReportFormContextMenu")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!
  const _component_ExportWirthTitlePagesModal = _resolveComponent("ExportWirthTitlePagesModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          ref: "headerRef",
          class: "reg-reports-v3-header"
        }, _toDisplayString(_ctx.formJournalCaption), 513),
        (_ctx.table?.tableDataSet.isTableMounted)
          ? (_openBlock(), _createBlock(_Teleport, {
              key: 0,
              disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
              to: '#' + _ctx.table.tableDataSet.idContainerDivUp
            }, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.table)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_TableActionsComponent, {
                        "table-data-set": _ctx.table.tableDataSet,
                        "changes-state": "hide",
                        "filter-state": "enable",
                        "row-append-state": "hide"
                      }, null, 8, ["table-data-set"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.actionsReportForms?.length)
                  ? (_openBlock(), _createBlock(_component_ActionsButtons, {
                      key: 1,
                      "actions-func-dict": _ctx.actionsFuncDict,
                      "data-array": _ctx.actionsReportForms,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onActionExec($event.data)))
                    }, null, 8, ["actions-func-dict", "data-array"]))
                  : _createCommentVNode("", true)
              ], 512),
              (_ctx.table.tableDataSet)
                ? (_openBlock(), _createBlock(_component_PanelFilters, {
                    key: 0,
                    "data-set": _ctx.table.tableDataSet.options.filtersSet,
                    "model-array": _ctx.table.tableDataSet.modelUnref,
                    "table-data-set": _ctx.table.tableDataSet,
                    types: _ctx.table.tableDataSet.types,
                    style: {"padding-bottom":"10px"},
                    "url-sync": ""
                  }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                : _createCommentVNode("", true)
            ], 8, ["disabled", "to"]))
          : _createCommentVNode("", true)
      ], 512),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.table)
          ? (_openBlock(), _createBlock(_component_ScreenSpinner, {
              key: 0,
              dimming: false
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_TableComponent, {
                  isShowInfo: false,
                  isViewColumnNumber: false,
                  "screen-spinner": _ctx.journalDataSet.pagination.loading,
                  "table-data-set": _ctx.table.tableDataSet,
                  "selection-mode": "multiple",
                  "onUpdate:cells": _cache[1] || (_cache[1] = ($event: any) => (_ctx.table.tableDataSet.updateCells($event))),
                  onRowAppend: _cache[2] || (_cache[2] = ($event: any) => (_ctx.table.tableDataSet.rowAppend())),
                  onRowInsert: _cache[3] || (_cache[3] = ($event: any) => (_ctx.table.tableDataSet.rowInsert($event))),
                  onRowDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.table.tableDataSet.rowDelete($event))),
                  onRowDuplicate: _cache[5] || (_cache[5] = ($event: any) => (_ctx.table.tableDataSet.rowDuplicate($event))),
                  onRowDown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.table.tableDataSet.rowMoveDown($event))),
                  onRowUp: _cache[7] || (_cache[7] = ($event: any) => (_ctx.table.tableDataSet.rowMoveUp($event))),
                  "onUpdate:cursor": _cache[8] || (_cache[8] = ($event: any) => (_ctx.setHelp(_ctx.table, $event))),
                  onClickColumnSelect: _ctx.onSwitchRow
                }, {
                  headers: _withCtx(({ classes, tableDataSet }) => [
                    _createVNode(_component_TableMultiHeaders, {
                      classes: classes,
                      "table-data-set": tableDataSet,
                      "left-col": "checkbox",
                      onClickLeftCol: _ctx.onSelectedAll
                    }, null, 8, ["classes", "table-data-set", "onClickLeftCol"])
                  ]),
                  context_menu: _withCtx(({
                tableDataSet,
                model,
                colIndex,
                rowIndex,
                data,
                original,
                tableRow,
                classes,
                type,
              }) => [
                    (_ctx.TableRow.isSelectable(tableRow))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_ButtonComponent, {
                            icon: _ctx.TABLE_ICONS.dotsVertical,
                            class: "button-opacity",
                            onClick: _withModifiers(($event: any) => (
                    _ctx.openContextMenuWithReportForm($event, original)
                  ), ["stop"])
                          }, null, 8, ["icon", "onClick"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  form_name: _withCtx(({
                tableDataSet,
                model,
                colIndex,
                rowIndex,
                data,
                original,
                tableRow,
                classes,
                type,
              }) => [
                    _createElementVNode("span", null, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.TableRow.isSelectable(tableRow) ? 'router-link' : 'span'), {
                        title: tableRow.original?.form_name,
                        to: `/editor-forms/${tableRow.original?.report_form_id}`,
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "to"]))
                    ])
                  ]),
                  _: 1
                }, 8, ["screen-spinner", "table-data-set", "onClickColumnSelect"])
              ]),
              (_ctx.table?.tableDataSet.isTableMounted)
                ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    disabled: !_ctx.table.tableDataSet.isFullScreenAndMounted,
                    to: '#' + _ctx.table.tableDataSet.idContainerDivBottom
                  }, [
                    _createVNode(_component_TablePaginationBottom, {
                      "table-data-set": _ctx.table.tableDataSet,
                      onPagination: _ctx.onPagination
                    }, null, 8, ["table-data-set", "onPagination"])
                  ], 8, ["disabled", "to"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.table.tableDataSet.helpHelper.help)
                  ? (_openBlock(), _createBlock(_component_BlueHelpComponent, {
                      key: 0,
                      style: {"margin":"24px","max-width":"30vw"},
                      onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.table.tableDataSet.helpHelper.setHelp(null)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.table.tableDataSet.helpHelper.help), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ], 64))
      ]),
      (_ctx.open.createReportForm)
        ? (_openBlock(), _createBlock(_component_CreateReportFormModal, {
            key: 0,
            "journal-name": _ctx.journalDataSet?.params.staticParams.journal_name,
            preset: _ctx.journalDataSet?.params.staticParams?.preset,
            onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.open.createReportForm = false)),
            onCreatedReport: _cache[11] || (_cache[11] = ($event: any) => {
        _ctx.open.createReportForm = false;
        _ctx.onAddReport($event);
      })
          }, null, 8, ["journal-name", "preset"]))
        : _createCommentVNode("", true),
      (_ctx.open.loadReportForm)
        ? (_openBlock(), _createBlock(_component_LoadReportForm, {
            key: 1,
            onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.open.loadReportForm = false)),
            onSuccess: _cache[13] || (_cache[13] = ($event: any) => {
        _ctx.open.loadReportForm = false;
        _ctx.updateJournal(false);
      })
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ReportFormContextMenu, {
        ref: "reportFormContextMenu",
        onSuccessDeleteForm: _ctx.onSuccessDeleteForm,
        onUpdateFormRead: _ctx.updateFormRead,
        onCloseEnterModal: _cache[14] || (_cache[14] = ($event: any) => (_ctx.open.enterModal = null)),
        onOpenEnterModal: _cache[15] || (_cache[15] = ($event: any) => (_ctx.open.enterModal = $event))
      }, null, 8, ["onSuccessDeleteForm", "onUpdateFormRead"])
    ]),
    (_ctx.open.enterModal)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 0,
          "model-value": _ctx.open.enterModal.modelValue,
          readonly: _ctx.open.enterModal.readonly,
          title: _ctx.open.enterModal.title,
          "title-save-button": _ctx.open.enterModal.titleSaveButton,
          onClose: _ctx.open.enterModal.onClose,
          "onUpdate:modelValue": _ctx.open.enterModal.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "title-save-button", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.open.export)
      ? (_openBlock(), _createBlock(_component_ExportWirthTitlePagesModal, {
          key: 1,
          "props-object": _ctx.open.export,
          onClose: _cache[16] || (_cache[16] = ($event: any) => (_ctx.open.export = null))
        }, null, 8, ["props-object"]))
      : _createCommentVNode("", true)
  ], 64))
}