import { ApiService, HttpService } from "table";
import { RepositoryExtensionDto } from "@/services/AppApiRepositoryService/types/RepositoryExtensionDto";
import {
  JournalRepositoryDto,
  JournalValueDto,
} from "@/services/AppApiRepositoryService/types/JournalRepositoryDto";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";

class AppApiRepositoryService {
  getQueryParams = ApiService.getQueryParams;

  getExtensionsList() {
    const url =
      "/api/repository/extensions?" +
      this.getQueryParams({
        action: "list",
      });
    return ApiService.useCache(url, () =>
      HttpService.get<ApiListWrapper<RepositoryExtensionDto>>(url),
    );
  }

  getFormsJournals() {
    const url =
      "/api/repository/forms_journals/types?" +
      this.getQueryParams({
        action: "list",
      });
    return ApiService.useCache(url, () =>
      HttpService.get<ApiListWrapper<JournalValueDto>>(url),
    );
  }

  getJournalRepository(journal_name: string) {
    return HttpService.get<JournalRepositoryDto>(
      "/api/repository/forms_journals?" +
        this.getQueryParams({
          action: "read",
          journal_name,
        }),
    );
  }
}

export default new AppApiRepositoryService();
