import { FormInfoDataDto, PaginationDataSet, TableDataSet } from "table";
import { JournalRequiredFieldsDto } from "@/services/AppApiRepositoryService/types/JournalRequiredFieldsDto";

export interface JournalDataSetStaticParams {
  journal_name: string;
  preset?: string;
  action: string;
}

export class JournalDataSet extends PaginationDataSet<
  JournalRequiredFieldsDto,
  JournalDataSetStaticParams
> {
  setInfoData(
    tableDataSet: TableDataSet,
    journalList: JournalRequiredFieldsDto[],
  ) {
    const infoData = {
      comment: journalList
        .map((x, row) => {
          if (!x.form_comment?.text) {
            return undefined;
          }

          return {
            pointer: {
              row,
              col_name: "form_name",
            },
            value: x.form_comment,
          } as FormInfoDataDto;
        })
        .filter(Boolean) as FormInfoDataDto[],
    };
    tableDataSet.setInfoData(infoData);
  }
}
